import { FC } from 'react'

import NavTabs from 'src/components/NavTabs/NavTabs'
import { getClientNavigationOptions } from 'src/components/Settings/Client/SettingsClientHelper'

const ClientSettingsNavigation: FC = () => {
  return <NavTabs tabs={getClientNavigationOptions(window.location.pathname)} />
}

export default ClientSettingsNavigation
